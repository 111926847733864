import React from 'react';
import * as yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, Button, Flex, FormControl, FormLabel, Input, Text, Textarea } from '@chakra-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PhoneIconDark from '../assets/phone-icon-dark.svg';
import LocationIcon from '../assets/location-icon.svg';
import BackgroundImage from 'gatsby-background-image/index';
import styled from '@emotion/styled';

const validationSchema = yup.object().shape({
    name: yup.string().min(2, 'Must be longer than 2 characters').required('Name is required'),
    email: yup.string().email().required('Email address is required'),
    message: yup
        .string()
        .min(2, 'Must be longer than 2 characters')
        .required('Message last name is required'),
});

const encode = (data) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
};

const StyledBanner = styled(BackgroundImage)(
    ({ theme }) => `
    &::before,
    &::after {
        filter: brightness(0.6);
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 8px solid ${theme.color.brand};
    margin-bottom: 80px;
    height: 300px;
    text-align: center;
    width: 100%;
`
);

const StyledErrorMessage = styled(ErrorMessage)(
    ({ theme }) => `
    color: ${theme.color.red[500]};
    font-size: ${theme.fontSizes.sm};
`
);

const StyledField = styled(Field)(
    ({ theme }) => `
    border: 1px solid ${theme.color.gray[200]};
    width: 100%;
`
);

const StyledLabel = styled(FormLabel)`
    margin-top: 10px;
`;

const StyledFormGroup = styled(Box)(
    ({ theme }) => `
        display: block;
        flex: 0 1 500px;
        
        input {
            border: 1px solid ${theme.color.gray[300]};
        }
        
        textarea {
            border: 1px solid ${theme.color.gray[300]};
        }

    `
);

const StyledSubmitButton = styled(Button)(
    ({ theme }) => `
    background-color: ${theme.color.black};
    color: ${theme.color.white};
    margin-top: 40px;
    width: 200px;
`
);

const ContactPage = () => {
    const data = useStaticQuery(graphql`
        query {
            contact: file(relativePath: { eq: "contact-unsplash.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
    return (
        <Layout>
            <SEO title="Contact Us" />
            <StyledBanner Tag="section" fluid={data.contact.childImageSharp.fluid}>
                <Box>
                    <Text fontSize="5xl" color="white" fontWeight="semibold" pt="120px">
                        Contact Us
                    </Text>
                </Box>
            </StyledBanner>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    message: '',
                }}
                onSubmit={(values, actions) => {
                    fetch('/', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        body: encode({ 'form-name': 'contact-form', ...values }),
                    })
                        .then(() => {
                            alert('Success');
                            actions.resetForm();
                        })
                        .catch(() => {
                            alert('Error');
                        })
                        .finally(() => actions.setSubmitting(false));
                }}
                validationSchema={validationSchema}>
                {() => (
                    <form name="contact-form" method="post" data-netlify="true">
                        <Flex
                            justifyContent="space-evenly"
                            direction={['column', 'row']}
                            mr={['20px', '80px']}
                            ml={['20px', '80px']}>
                            <StyledFormGroup>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="carrier">Name</StyledLabel>
                                    <StyledField as={Input} name="name" required />
                                    <StyledErrorMessage name="name" component="ErrorMessage" />
                                </FormControl>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="insuredLastName">Email</StyledLabel>
                                    <StyledField as={Input} name="email" />
                                    <StyledErrorMessage name="email" component="ErrorMessage" />
                                </FormControl>
                                <FormControl isRequired>
                                    <StyledLabel htmlFor="suite">Message</StyledLabel>
                                    <StyledField as={Textarea} name="message" />
                                    <StyledErrorMessage name="message" component="ErrorMessage" />
                                </FormControl>
                                <Box w="100%" textAlign="center">
                                    <StyledSubmitButton
                                        variant="solid"
                                        borderRadius="0"
                                        type="submit"
                                        size="lg"
                                        _hover={{ bg: '#ffca10', color: 'black' }}>
                                        Send
                                    </StyledSubmitButton>
                                </Box>
                            </StyledFormGroup>
                            <Box w={['100%', '50%']}>
                                <Text fontSize="3xl" fontWeight="bold" mb="20px">
                                    Get in touch
                                </Text>
                                <Text mb="20px">
                                    For more information about our services or to speak with one of
                                    our claims specialist, please call or email us and one of our
                                    specialist will be happy to assist you.
                                </Text>
                                <Text>
                                    We look forward to working with you and appreciate your
                                    business.
                                </Text>
                                <Flex justifyContent="flex-start" alignItems="center" mt="20px">
                                    <PhoneIconDark />
                                    <Text ml="10px">Call us: 1.800.909.2711</Text>
                                </Flex>
                                <Flex justifyContent="flex-start" alignItems="center" mt="20px">
                                    <LocationIcon />
                                    <Text ml="10px">Located in: Dawsonville, Georgia</Text>
                                </Flex>
                            </Box>
                        </Flex>
                        <input type="hidden" name="form-name" value="contact-form" />
                    </form>
                )}
            </Formik>
        </Layout>
    );
};

export default ContactPage;
